<template>
  <b-card>
    <b-card-header>
      <span>
        <strong>
          <feather-icon icon="UsersIcon" />
          {{ $t('Conversation.conversation') }}
        </strong>
      </span>
    </b-card-header>
    <b-card-body>
      <!-- comments -->
      <div
        v-for="comment in conversationData.conversacionMensajes"
        :key="comment.conversacionMensajeId"
        class="d-flex align-items-start mb-1"
      >
        <b-avatar
          :text="avatarText(comment.nombreCompleto)"
          size="34"
          class="mt-25 mr-75"
        />
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">
              {{ comment.nombreCompleto }}
            </h6>
          </div>
          <small>{{ comment.fechaHora }} - {{ comment.mensaje }}</small>
          <b-row>
            <b-col
              v-for="file in comment.conversacionMensajeAdjuntos"
              :key="file.conversacionMensajeAdjuntoId"
              cols="12"
              md="4"
            >
              <span
                class="cursor-pointer"
                @click="onDownloadFile(file)"
              >
                <feather-icon icon="PaperclipIcon" />
                {{ file.archivo }}.{{ file.extension }}
              </span>
            </b-col>
          </b-row>
        </div>
      </div>
      <!--/ comments -->

      <!-- comment box -->
      <b-form-group>
        <b-form-textarea
          v-model="newMessage.mensaje"
          rows="3"
          :placeholder="$t('Conversation.addComment')"
        />
      </b-form-group>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('Conversation.attachDocument')"
          >
            <file-uploader
              v-model="newFile.guid"
              :file-name.sync="newFile.name"
              @on-upload-file="onUploadFile"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-for="file in newMessage.conversacionMensajeAdjuntos"
          :key="file.guid"
          cols="12"
          md="3"
        >
          <span
            class="cursor-pointer"
          >
            <feather-icon icon="PaperclipIcon" />
            {{ file.name }}
          </span>
          <span
            class="cursor-pointer"
            @click="onDeleteFile(file.guid)"
          >
            <feather-icon icon="XCircleIcon" />
          </span>
        </b-col>
      </b-row>
      <!--/ comment box -->

      <b-button
        v-if="conversationData.conversacionId"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
        :disabled="newMessage.mensaje === '' || sendingMessage"
        @click="onPostMessage"
      >
        <b-spinner
          v-if="sendingMessage"
          small
          class="mr-1"
        />
        <feather-icon icon="PenToolIcon" />
        {{ $t('Conversation.comment') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  VBTooltip,
  BCardHeader,
  BCard,
  BCardBody,
  BFormGroup,
  BFormTextarea,
  BButton,
  BRow,
  BCol,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { saveAs } from 'file-saver'
import FileUploader from '@/components/FileUploader.vue'
import conversationService from '@/services/conversation.service'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BFormGroup,
    BFormTextarea,
    BButton,
    BAvatar,
    BRow,
    BCol,
    FileUploader,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    /* Services */
    const {
      fetchConversation,
      createConversationMessage,
      fetchConversationMessageFile,
    } = conversationService()
    /* Data */
    const conversationData = ref({})

    const refetchData = conversacionId => {
      fetchConversation(conversacionId || conversationData.value.conversacionId, data => {
        conversationData.value = data
      })
    }
    const newFileBlank = {
      guid: '',
      name: '',
    }
    const newMessageBlank = {
      mensaje: '',
      usuario: '-',
      nombreCompleto: '-',
      conversacionMensajeAdjuntos: [],
    }
    const newFile = ref(JSON.parse(JSON.stringify(newFileBlank)))
    const newMessage = ref(JSON.parse(JSON.stringify(newMessageBlank)))
    /* UI */
    const sendingMessage = ref(false)

    /* Events */
    const onUploadFile = () => {
      newMessage.value.conversacionMensajeAdjuntos.push(newFile.value)
      newFile.value = JSON.parse(JSON.stringify(newFileBlank))
    }
    const onDeleteFile = guid => {
      newMessage.value.conversacionMensajeAdjuntos = newMessage.value.conversacionMensajeAdjuntos.filter(f => f.guid !== guid)
    }
    const onDownloadFile = item => {
      fetchConversationMessageFile(item.conversacionMensajeAdjuntoId, data => {
        const file = new File([data], `${item.archivo}.${item.extension}`)
        saveAs(file, `${item.archivo}.${item.extension}`)
      })
    }

    const onPostMessage = () => {
      sendingMessage.value = true
      createConversationMessage(conversationData.value.conversacionId, newMessage.value)
        .then(() => {
          newMessage.value = JSON.parse(JSON.stringify(newMessageBlank))
          refetchData()
          sendingMessage.value = false
        })
    }

    return {
      // Data
      conversationData,
      newFile,
      newMessage,
      // UI
      avatarText,
      sendingMessage,
      // Events
      refetchData,
      onUploadFile,
      onDeleteFile,
      onDownloadFile,
      onPostMessage,
    }
  },
}
</script>

<style>

</style>
