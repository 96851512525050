<template>
  <div>
    <b-card
      class="p-1"
      no-body
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Estado del hallazgo"
          >
            <v-select
              v-model="eventData.auditoriaEstadoEventoId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="texto"
              :options="eventStatus"
              :reduce="option => option.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Es Hallazgo"
          >
            <b-form-checkbox
              v-model="eventData.esHallazgo"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Observaciones"
          >
            <b-form-textarea v-model="eventData.seguimientoObservaciones" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onUpdateData"
      >
        {{ $t('Lists.saveChanges') }}
      </b-button>
    </b-card>
    <b-card
      no-body
      class="p-3"
    >
      <conversation ref="refConversation" />
    </b-card>
  </div>

</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import router from '@/router'
import dashboardService from '@/services/dashboard.service'
import Conversation from '@/components/Conversation.vue'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    Conversation,
    vSelect,
  },
  setup() {
    const refConversation = ref(null)

    const { auditoriaBitacoraEventoID } = router.currentRoute.params

    const {
      fetchAuditoriaMolexEventoDetalle,
      updateAuditoriaMolexEventoDetalle,
    } = dashboardService()

    const {
      fetchOptions,
    } = optionService()

    const eventStatus = ref([])
    fetchOptions({ nombreDominio: 'AuditoriaEstadoEventos' }, data => {
      eventStatus.value = data
    })

    const eventData = ref({
      seguimientoObservaciones: '',
      auditoriaEstadoEventoId: 2,
      esHallazgo: true,
    })

    fetchAuditoriaMolexEventoDetalle(auditoriaBitacoraEventoID, data => {
      eventData.value = data
      refConversation.value.refetchData(data.conversacionId)
    })

    const onUpdateData = () => {
      updateAuditoriaMolexEventoDetalle(auditoriaBitacoraEventoID, eventData.value, () => {
        router.push({ name: 'auditoria-molex' })
      })
    }

    return {
      refConversation,

      eventStatus,
      eventData,

      onUpdateData,
    }
  },
}
</script>

<style>

</style>
