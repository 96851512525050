import axios from '@axios'
import common from '@/libs/common'

export default function conversationService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  const fetchConversation = (conversacionId, callback) => {
    axios
      .get(`${API_URL}/Conversaciones/${conversacionId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createConversationMessage = (conversacionId, data) => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Conversaciones/${conversacionId}/Mensaje`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        throwError(error)
        reject()
      })
  })

  const fetchConversationMessageFile = (conversacionMensajeAdjuntoId, callback) => {
    axios
      .get(`${API_URL}/Conversaciones/MensajeArchivo/${conversacionMensajeAdjuntoId}`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchConversation,
    createConversationMessage,
    fetchConversationMessageFile,
  }
}
