import axios from '@axios'
import common from '@/libs/common'
import defaultService from './_default.service'

export default function dashboardService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`
  const defaultMethods = defaultService('Tableros')

  // *=================================================*
  // *--------- Elements config -----------------------*
  // *===============================================--*
  const fetchElement = (data, callback) => defaultMethods.fetch('Elementos', { }, data, callback)
  const fetchElementById = (data, callback) => defaultMethods.fetch('Elementos/%{elementoId}', { elementoId: data.elementoId }, data, callback)
  const fetchAvalibleElements = callback => defaultMethods.fetch('ElementosDisponibles', { }, {}, callback)
  const createElement = (data, callback) => defaultMethods.create('Elementos', {}, false, data, callback)
  const updateElement = (data, callback) => defaultMethods.update('Elementos/%{elementoId}', { elementoId: data.elementoId }, false, data, callback)
  const removeElement = (elementoId, callback) => defaultMethods.remove('Elementos/%{elementoId}', { elementoId }, true, callback)

  const fetchElementQueryObject = (data, callback) => defaultMethods.fetch('Elementos/%{elementoId}/ObjetoConsulta/', { elementoId: data.elementoId }, data, callback)
  const updateElementQueryObject = (data, callback) => defaultMethods.update('Elementos/%{elementoId}/ObjetoConsulta/%{objetoConsultaId}', { elementoId: data.elementoId, objetoConsultaId: data.objetoConsultaId }, false, data, callback)

  const fetchElementConditionFilters = (data, callback) => defaultMethods.fetch('Elementos/%{elementoId}/CondicionFiltrado', { elementoId: data.elementoId }, data, callback)
  const createElementConditionFilter = (elementoId, data, callback) => defaultMethods.create('Elementos/%{elementoId}/CondicionFiltrado', elementoId, true, data, callback)
  const updateElementConditionFilters = (data, callback) => defaultMethods.update('Elementos/%{elementoId}/CondicionFiltrado', { elementoId: data.elementoId }, false, data.condicionesFiltrado, callback)
  const removeElementConditionFilter = (data, callback) => defaultMethods.remove('Elementos/%{elementoId}/CondicionFiltrado/%{elementoCondicionFiltradoId}', { elementoId: data.elementoId, elementoCondicionFiltradoId: data.elementoCondicionFiltradoId }, true, callback)

  const fetchElementTabColumns = (data, callback) => defaultMethods.fetch('Elementos/%{elementoId}/TabularColumnas', { elementoId: data.elementoId }, data, callback)
  const updateElementTabColumns = (data, callback) => defaultMethods.update('Elementos/%{elementoId}/TabularColumnas', { elementoId: data.elementoId }, false, data.tabularColumnas, callback)

  const createElementTabGetData = (elementoId, data, callback) => defaultMethods.create('Elementos/%{elementoId}/TabularDatos', { elementoId }, false, data, callback)
  const createElementTabGetExcelFile = (elementoId, data, callback) => defaultMethods.downloadFileByPost('Elementos/%{elementoId}/TabularExcel', { elementoId }, data, callback)

  // const fetchRulesAccessUserElement = (data, callback) => defaultMethods.fetch('ReglaAcceso', { }, data, callback)
  // const createRulesAccessElement = (data, callback) => defaultMethods.create('ReglaAcceso', { }, true, data, callback)
  // const fetchRulesAccessUserElement = (data, callback) => {
  //   axios
  //     .fetch(`${API_URL}/Tableros/ReglaAcceso`, data)
  //     .then(response => {
  //       callback(response)
  //     })
  //     .catch(error => throwError(error))
  // }
  const fetchRulesAccessUserElement = (data, callback) => {
    axios
      .get(`${API_URL}/Tableros/ReglaAcceso/`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createRulesAccessElement = data => new Promise((resolver, reject) => {
    axios
      .post(`${API_URL}/Tableros/ReglaAcceso`, data)
      .then(response => {
        throwSuccess(response)
        resolver()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const deleteRulesAccessElement = (elementoId, callback) => defaultMethods.remove('ReglaAcceso/%{elementoId}', { elementoId }, true, callback)
  // const deleteRulesAccessElement = (data, callback) => {
  //   axios
  //     .delete(`${API_URL}/Tableros/ReglaAcceso/%{elementoId}`, { elementoId: data })
  //     .then(response => {
  //       callback(response.data)
  //     })
  //     .catch(error => {
  //       throwError(error)
  //     })
  // }
  // *=================================================*
  // *--------- User dashboard ------------------------*
  // *===============================================--*
  const fetchDashboard = (data, callback) => defaultMethods.fetch('Usuario', { }, data, callback)
  const fetchNewDashboard = (data, callback) => defaultMethods.fetch('Usuario/NuevoModelo', {}, data, callback)
  const fetchDashboardUserElementTabFields = (tableroUsuarioElementoId, callback) => defaultMethods.fetch('Usuario/Elementos/%{tableroUsuarioElementoId}/TabularCampos', { tableroUsuarioElementoId }, {}, callback)
  const createDashboardUserElement = (data, callback) => defaultMethods.create('Usuario/Elementos', {}, true, data, callback)
  const updateDashboardUserElement = (tableroUsuarioElementoId, data, callback) => defaultMethods.update('Usuario/Elementos/%{tableroUsuarioElementoId}', { tableroUsuarioElementoId }, true, data, callback)
  const removeDashboardUserElement = (tableroUsuarioElementoId, callback) => defaultMethods.remove('Usuario/Elementos/%{tableroUsuarioElementoId}', { tableroUsuarioElementoId }, true, callback)

  const fetchDhashboardUserElementTabColumns = (tableroUsuarioElementoId, callback) => defaultMethods.fetch('Usuario/Elementos/%{tableroUsuarioElementoId}/TabularColumnas', { tableroUsuarioElementoId }, {}, callback)
  const updateDhashboardUserElementTabColumns = (tableroUsuarioElementoId, data, callback) => defaultMethods.update('Usuario/Elementos/%{tableroUsuarioElementoId}/TabularColumnas', { tableroUsuarioElementoId }, true, data, callback)
  const createDashboardUserElementTabGetExcelFile = (tableroUsuarioElementoId, data, callback) => defaultMethods.downloadFileByPost('Usuario/Elementos/%{tableroUsuarioElementoId}/TabularExcel', { tableroUsuarioElementoId }, data, callback)
  const createDashboardUserElementTabGetData = (tableroUsuarioElementoId, data, callback) => defaultMethods.create('Usuario/Elementos/%{tableroUsuarioElementoId}/TabularDatos', { tableroUsuarioElementoId }, false, data, callback)
  // *=================================================*
  // *--------- Auditoria Molex -----------------------*
  // *===============================================--*
  const fetchAuditoriaMolexControlHallazgos = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/ControlHallazgos`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchAuditoriaMolexGrafica = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/GraficasHallazgos`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchAuditoriaMolexEventos = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/Eventos`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchAuditoriaMolexEventoDetalle = (auditoriaBitacoraEventoId, callback) => {
    axios
      .get(`${API_URL}/Auditorias/Eventos/${auditoriaBitacoraEventoId}/Detalle`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const updateAuditoriaMolexEventoDetalle = (auditoriaBitacoraEventoId, data, callback) => {
    axios
      .post(`${API_URL}/Auditorias/Eventos/${auditoriaBitacoraEventoId}/Detalle`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchAuditoriaMolexGraficaCalificacion = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/GraficasCalificaciones`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchAuditoriaMolexRectificaciones = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/Rectificaciones`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchAuditoriaMolexRectificacionesGraficas = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/RectificacionesGraficas`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchAuditoriaMolexExportarExcelHallazgos = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/ExportarExcelHallazgos`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createAuditoriaMolexImportarExcelHallazgos = (data, callback, errorCallback) => {
    axios
      .post(`${API_URL}/Auditorias/ImportarExcelHallazgos`, data)
      .then(response => callback(response.data))
      .catch(error => errorCallback(error.response.data))
  }

  const fetchAuditoriaMolexSeguimientoGraficas = (data, callback) => {
    axios
      .get(`${API_URL}/Auditorias/SeguimientoGraficas`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchElement,
    fetchElementById,
    fetchAvalibleElements,
    createElement,
    updateElement,
    removeElement,

    fetchElementQueryObject,
    updateElementQueryObject,
    fetchElementConditionFilters,
    createElementConditionFilter,
    updateElementConditionFilters,
    removeElementConditionFilter,
    fetchElementTabColumns,
    updateElementTabColumns,
    createElementTabGetData,
    createElementTabGetExcelFile,
    fetchRulesAccessUserElement,
    createRulesAccessElement,
    deleteRulesAccessElement,

    fetchDashboard,
    fetchNewDashboard,
    fetchDashboardUserElementTabFields,
    createDashboardUserElement,
    updateDashboardUserElement,
    removeDashboardUserElement,
    fetchDhashboardUserElementTabColumns,
    updateDhashboardUserElementTabColumns,
    createDashboardUserElementTabGetExcelFile,
    createDashboardUserElementTabGetData,
    // Auditoria Molex
    fetchAuditoriaMolexControlHallazgos,
    fetchAuditoriaMolexGrafica,
    fetchAuditoriaMolexEventos,
    fetchAuditoriaMolexEventoDetalle,
    updateAuditoriaMolexEventoDetalle,
    fetchAuditoriaMolexGraficaCalificacion,
    fetchAuditoriaMolexRectificaciones,
    fetchAuditoriaMolexRectificacionesGraficas,
    fetchAuditoriaMolexExportarExcelHallazgos,
    createAuditoriaMolexImportarExcelHallazgos,
    fetchAuditoriaMolexSeguimientoGraficas,
  }
}
